// import { Link } from "gatsby"
import React from "react"
import imgSrc from '../images/contato.jpg'
import './contato.css'
import { FaPhone, FaEnvelope, FaMapPin } from 'react-icons/fa';

const Contato = () => (
  <div className="contato">
    <div className="contato-overlay"></div>
    <div className="contato-text">        
      <div className="col-md-7 col-sm-0 map">
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe width="400" height="400" id="gmap_canvas" src="https://maps.google.com/maps?q=R%20Manoel%20Teixeira%20de%20Freitas%2C%202442&t=&z=15&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe></div>
        </div>
      </div>
      <div className="col-md-5 col-sm-12">
        <h3>Fale conosco</h3>
        <p>Temos profissionais capacitados prontos para atuar derrubando valores abusivos da sua fatura.</p>
        <hr />
        <a href="tel:(18) 3723-6582" className="contact-link"><FaPhone style={{color: '#ffb222', marginRight: 10}} />(18) 3723-5170</a>
        <a href="mailto:contato@ecoteleconomia.com.br" className="contact-link"><FaEnvelope style={{color: '#ffb222', marginRight: 10}}/>contato@ecoteleconomia.com.br</a>     
        <a href="https://maps.google.com/maps?q=R%20Manoel%20Teixeira%20de%20Freitas%2C%202442&t=&z=15&ie=UTF8&iwloc=" target="_blank" className="contact-link"><FaMapPin style={{color: '#ffb222', marginRight: 10}}/>R. Manoel Teixeira de Freitas, 2442 - Andradina - SP</a>     
        
      </div>    
    </div>
    <div className="contato-background">
      <img src={imgSrc} alt="Reduzindo custos" />
    </div>    
  </div>
);

export default Contato
