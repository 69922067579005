// import { Link } from "gatsby"
import React from "react"
import imgSrc from '../images/accounting.jpg'
import './home-slide02.css'
import Slide from 'react-reveal/Slide';
import Button from "react-bootstrap/Button"

const HomeSlide02 = () => (
  <div className="home-slide02">
    <div className="home-slide02-overlay"></div>
    <div className="home-slide02-text">
      <div className="col-md-6 col-sm-12">
        <Slide right>       
          <h3>Reduza seus custos com telefonia<br /> e transforme essas despesas em lucro!</h3>
        </Slide>
        <hr />      
        <Slide right>
          <h4>Fornecemos assessoria no setor de telefonia fixa e móvel.<br />Contamos com profissionais aptos e atendemos em todo Brasil.</h4>
        </Slide>
      </div>      
      {/* <div className="col-6">
        <Button variant="outline-warning" >Iaculis nibh nec eleifend</Button>    
        <Button variant="outline-warning" >Curabitur tristique</Button>    
      </div> */}
    </div>
    <div className="home-slide02-background">
      <img src={imgSrc} alt="Reduzindo custos" />
    </div>    
  </div>
);

export default HomeSlide02
