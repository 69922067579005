/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { StickyContainer, Sticky } from 'react-sticky';
import { Widget } from 'react-chat-widget';
import WhatsappButton from "./whatsapp-button"
import StickyHeader from "./sticky-header"
import Header from "./header"
import Footer from "./footer"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Dropzone from './dropzone'
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { FaPhone, FaEnvelope } from 'react-icons/fa'
import contactJpg from '../images/accounting.jpg';
import axios from 'axios';

import 'react-chat-widget/lib/styles.css';
import "./layout.css"

const Layout = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [emailSended, setEmailSended] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [file, setFile] = useState(null);
  const [inputs, setInputs] = useState({name: '', email: '', phone: '', company: ''});

  const setModalVisible = (visible) => {    
    setEmailSended(false);
    setInputs({name: '', email: '', phone: '', company: ''});
    setFile(null);
    setEmailError('');
    setVisible(visible);
  }
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let toggler;
  let isOpened = false;

  const handleSubmit = event => {
    
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      
    }

    event.preventDefault();
    event.stopPropagation();

    let fd = new FormData();

    if (inputs['name'] != ''){
      fd.append('name', inputs['name']);
    }
    
    if (inputs['email'] != ''){
      fd.append('email', inputs['email']);
    }
    
    if (inputs['phone'] != ''){
      fd.append('phone', inputs['phone']);
    }
    
    if (inputs['company'] != ''){
      fd.append('company', inputs['company']);
    }

    if (file !== null) {
      fd.append('file', file);
    }

    axios({
      method: 'post',
      url: `http://ecoteleconomia.com.br/contact.php`,
      data: fd,
      headers: {
        'content-type': `multipart/form-data; boundary=${fd._boundary}`,
      },
    })
    .then(res => {
      let { data } = res;
      debugger;
      if (data.success == true) {
        setEmailSended(true);
        setInputs({name: '', email: '', phone: '', company: ''});
        setFile(null);
      } else {
        setEmailError(data.error);
      }
    })
    .catch(error => {
      setEmailError(data.error);
    });
    
    return false;

  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
  }

  return (
    <StickyContainer>
      <Widget 
        title="Vamos falar sobre seu negócio?"
        subtitle="Disponível de Seg a Sex das 08:00 às 18:00 hrs."
        senderPlaceHolder="Digite sua mensagem"
        handleNewUserMessage={(msg) => {
          let phone = '5518996600077';
          msg = encodeURIComponent(msg);
          window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${msg}`, '_blank');
          toggler();
        }}
        launcher={handleToggle => {
          toggler = () => {
            isOpened = !isOpened;
            handleToggle();
          }
          return <WhatsappButton key='whatsapp-button' isOpened={isOpened} onClick={toggler} />
        }}
      />
      <Header />
      <Sticky topOffset={45}>
        {({
          style,

          // the following are also available but unused in this example
          isSticky,
          wasSticky,
          distanceFromTop,
          distanceFromBottom,
          calculatedHeight
        }) => (
          <StickyHeader openModal={() => setModalVisible(true)} style={style} siteTitle={data.site.siteMetadata.title} />
        )}
      </Sticky>       
      <main>{children}</main>
      <Footer openModal={() => setModalVisible(true)}/>
      <Rodal visible={visible} onClose={() => setModalVisible(false)} width={85} height={85} measure="%">
        <Container fluid className="h-100">
          <Row noGutters className="align-items-center h-100">
            <Col className="h-100 contact-info" md={6} sm={12}>
              <h3>Quer reduzir seus custos com telefonia em até 75%?</h3>
              <p>Temos profissionais capacitados prontos para atuar derrubando valores abusivos da sua fatura. Deixe seus dados no formulário ao lado, envie sua fatura e nós entraremos em contato logo em seguida.</p>
              <a href="tel:(18) 3723-6582" className="contact-link"><FaPhone style={{color: '#ffb222', marginRight: 10}} />(18) 3723-5170</a>
              <a href="mailto:contato@ecoteleconomia.com.br" className="contact-link"><FaEnvelope style={{color: '#ffb222', marginRight: 10}}/>contato@ecoteleconomia.com.br</a>     
            </Col >
            <Col className="h-100 bg-contact" md={6} sm={12} style={{backgroundImage: `url(${contactJpg})`, backgroundPosition: 'center', backgroundSize: 'cover'}}>
              <div className="backdrop"></div>
              <div className="form-content">
                { 
                  emailSended ? 
                  <h4 style={{textAlign: 'center', color: 'white'}}>Sua mensagem foi enviada com sucesso! Em breve entraremos em contato.</h4> :
                  <Form noValidate onSubmit={handleSubmit}>                    
                    <Form.Group controlId="formName" style={{marginBottom: 7}}>
                      { emailError != '' && 
                        <Form.Control.Feedback type="invalid">
                          {emailError}
                        </Form.Control.Feedback>
                      }
                      
                      <Form.Control type="text" placeholder="Nome" name="name" onChange={handleInputChange} value={inputs.name} required  />
                    </Form.Group>
                    <Form.Group controlId="formEmail" style={{marginBottom: 7}}>
                      <Form.Control type="text" placeholder="E-mail" name="email" onChange={handleInputChange} value={inputs.email}  required />
                    </Form.Group>
                    <Form.Group controlId="formPhone" style={{marginBottom: 7}}>
                      <Form.Control type="text" placeholder="Telefone" name="phone" onChange={handleInputChange} value={inputs.phone} required />
                    </Form.Group>
                    <Form.Group controlId="formCompany" style={{marginBottom: 7}}>
                      <Form.Control type="text" placeholder="Empresa" name="company" onChange={handleInputChange} value={inputs.company} required />
                    </Form.Group>
                    <Dropzone setFile={setFile} />
                    <Button variant="warning" block type="submit">
                      ENVIAR
                    </Button>
                  </Form>
                }                
              </div>              
            </Col>
          </Row>
        </Container>
      </Rodal>
    </StickyContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
