// import { Link } from "gatsby"
import React from "react"
import movieWebm from '../assets/video.webm'
import movieMp4 from '../assets/video.mp4'
import './home-hero.css'
import Slide from 'react-reveal/Slide'

const HomeHero = () => (
  <div className="home-hero">
    <div className="home-hero-overlay"></div>
    <div className="home-hero-text">
      <Slide down>
        <h2>QUER REDUZIR SUAS CONTAS DE TELEFONIA EM ATÉ 75%?</h2>
      </Slide>
      <hr />      
      <Slide up>
        <h3>A Ecotel oferece uma solução inteligente para sua empresa.<br />Faça uma avaliação e veja como podemos te ajudar.</h3>
      </Slide>
    </div>
    <div className="home-hero-background">
      <video autoPlay loop tabIndex="0" playsInline muted>
        <source src={movieMp4} type="video/mp4" />
        <source src={movieWebm} type='video/webm; codecs="vp8, vorbis"' />
      </video>
    </div>    
  </div>
);

export default HomeHero
