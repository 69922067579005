import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeHero from "../components/home-hero"
import Button from "react-bootstrap/Button"
import ProgressBar from "react-bootstrap/ProgressBar"
import CardDeck from "react-bootstrap/CardDeck"
import Card from "react-bootstrap/Card"
import Reveal from 'react-reveal/Reveal';
import Fade from 'react-reveal/Fade';
import growthAnalyticsImg from '../images/growth-analytics.svg';
import contactJpg from '../images/accounting.jpg';
import { FaShare, FaBuilding, FaWifi, FaFileInvoice, FaFileInvoiceDollar, FaPhone, FaEnvelope } from 'react-icons/fa'
import HomeSlide02 from '../components/home-slide02'
import Contato from '../components/contato'
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Dropzone from '../components/dropzone'
import * as Scroll from 'react-scroll';
import Customers from '../components/customers'

class IndexPage extends React.Component {

  constructor(props) {
      super(props);
      this.state = { visible: false };
  }

  show() {
      this.setState({ visible: true });
  }

  hide() {
      this.setState({ visible: false });
  }

  render() {
    return (
    <Layout>    
      <SEO title="Home" />
      <Rodal visible={this.state.visible} onClose={this.hide.bind(this)} width={85} height={85} measure="%">
        <Container fluid className="h-100">
          <Row noGutters className="align-items-center h-100">
            <Col className="h-100 contact-info" md={6} sm={12}>
              <h3>Quer reduzir seus custos com telefonia em até 75%?</h3>
              <p>Temos profissionais capacitados prontos para atuar derrubando valores abusivos da sua fatura. Deixe seus dados no formulário ao lado, envie sua fatura e nós entraremos em contato logo em seguida.</p>
              <a href="tel:(18) 3723-6582" className="contact-link"><FaPhone style={{color: '#ffb222', marginRight: 10}} />(18) 3723-5170</a>
              <a href="mailto:contato@ecoteleconomia.com.br" className="contact-link"><FaEnvelope style={{color: '#ffb222', marginRight: 10}}/>contato@ecoteleconomia.com.br</a>     
            </Col >
            <Col className="h-100 bg-contact" md={6} sm={12} style={{backgroundImage: `url(${contactJpg})`, backgroundPosition: 'center', backgroundSize: 'cover'}}>
              <div className="backdrop"></div>
              <div className="form-content">
                <Form>
                  <Form.Group controlId="formName" style={{marginBottom: 7}}>
                    <Form.Control type="text" placeholder="Nome" />
                  </Form.Group>
                  <Form.Group controlId="formEmail" style={{marginBottom: 7}}>
                    <Form.Control type="text" placeholder="E-mail" />
                  </Form.Group>
                  <Form.Group controlId="formPhone" style={{marginBottom: 7}}>
                    <Form.Control type="text" placeholder="Telefone" />
                  </Form.Group>
                  <Form.Group controlId="formCompany" style={{marginBottom: 7}}>
                    <Form.Control type="text" placeholder="Empresa" />
                  </Form.Group>
                  <Dropzone />
                  <Button variant="warning" block type="submit">
                    ENVIAR
                  </Button>
                </Form>
              </div>              
            </Col>
          </Row>
        </Container>
      </Rodal>
      <HomeHero />          
      <Scroll.Element name="QuemSomos">
        <div className="container mt-5 mb-5">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <Reveal>
                <h3>
                  Nós da Ecotel Economia podemos ajudar a sua empresa a otimizar o seu tempo e reduzir os seus custos usando ferramentas inteligentes e de acordo com as normas da ANATEL.              
                </h3>  
                <hr />
                <h4>
                  Venha já para Ecotel Economia, venha descobrir o quanto podemos reduzir sua despesa com telefonia em até 75% mensal.
                </h4>
              </Reveal>   
              <div className="row mt-5">            
                <div className="col-md-6 col-sm-12">
                  <Button variant="warning" block onClick={this.show.bind(this)}>FAÇA UMA AVALIAÇÃO</Button>              
                </div>
                <div className="col-md-6 col-sm-12">
                  <Button variant="dark" block onClick={() =>
                    Scroll.scroller.scrollTo('SaibaMais', {
                      duration: 500,
                      delay: 50,
                      smooth: true,
                      offset: -85
                  })}>SAIBA MAIS</Button>         
                </div>
              </div>       
            </div>
            <div className="col-md-6 col-sm-12">
              <Fade right>
                <img src={growthAnalyticsImg} alt="Reduzindo custos" className="pl-2 pr-2" />
              </Fade>
            </div>
          </div>
        </div>
      </Scroll.Element>
      <Scroll.Element name="SaibaMais">
        <div className="container mt-5 mb-5" >
          <div className="row">
            <div className="col-12 mt-3 mb-3">
              <h3 style={{textAlign: 'center'}}>
                Conheça os serviços que a Ecotel Economia pode proporcionar para sua empresa.
              </h3>  
              <h6 style={{textAlign: 'center', color: '#777'}}>
                Temos profissionais capacitados prontos para atuar derrubando valores abusivos da sua fatura.
              </h6>               
            </div>
            <div className="col-12">
              <CardDeck className="mt-1">  
                <Card border="light">
                  <Card.Body>
                    <Card.Title>
                      <FaFileInvoice />
                    </Card.Title>
                    <Card.Text>
                      Assinaturas
                      <span style={{float: 'right'}}>60%</span>
                    </Card.Text>
                    <ProgressBar variant="warning" now={60} />
                  </Card.Body>
                </Card>         
                <Card border="light">
                  <Card.Body>
                    <Card.Title>
                      <FaShare />
                    </Card.Title>
                    <Card.Text>
                      Compartilhamento
                      <span style={{float: 'right'}}>40%</span>
                    </Card.Text>
                    <ProgressBar variant="warning" now={40} />
                  </Card.Body>
                </Card>
                <Card border="light">
                  <Card.Body>
                    <Card.Title>
                      <FaWifi />
                    </Card.Title>
                    <Card.Text>
                      Internet
                      <span style={{float: 'right'}}>30%</span>
                    </Card.Text>
                    <ProgressBar variant="warning" now={30} />
                  </Card.Body>
                </Card>                  
              </CardDeck>
              <CardDeck className="mt-3">        
                <Card border="light">
                  <Card.Body>
                    <Card.Title>
                      <FaFileInvoiceDollar/>
                    </Card.Title>
                    <Card.Text>
                      Tarifas de chamadas
                      <span style={{float: 'right'}}>90%</span>
                    </Card.Text>
                    <ProgressBar variant="warning" now={90} />
                  </Card.Body>
                </Card>
                <Card border="light">
                  <Card.Body>
                    <Card.Title>
                      <FaBuilding />
                    </Card.Title>
                    <Card.Text>
                      Serviços de terceiros
                      <span style={{float: 'right'}}>100%</span>
                    </Card.Text>
                    <ProgressBar variant="warning" now={100} />
                  </Card.Body>
                </Card>                    
              </CardDeck>          
              <Button variant="outline-warning" className="mt-4 col-md-4 offset-md-4 col-sm-12" onClick={this.show.bind(this)}>FAÇA UMA AVALIAÇÃO EM SUA FATURA</Button>    
            </div>
          </div>
        </div>
      </Scroll.Element>      
      <HomeSlide02 />
      <Customers />
      <Scroll.Element name="Contato">
        <Contato />
      </Scroll.Element>
    </Layout>)
  }
}

export default IndexPage
