// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'
import "./header.css"

const Header = () => (
  <Navbar style={{padding: 0, backgroundColor: '#333'}} id="header">
    <Container>       
      <Nav className="mr-auto">                   
        <Nav.Link href="tel:(18) 3723-6582" className="contact-link">(18) 3723-5170</Nav.Link>
        <Nav.Link href="mailto:contato@ecoteleconomia.com.br" className="contact-link">contato@ecoteleconomia.com.br</Nav.Link>
      </Nav>
      <Nav>
        <Nav.Link target="_blank" href="https://www.facebook.com/ecoteleconomiaetelefonia/" className="social-link"><FaFacebook /></Nav.Link>
        <Nav.Link target="_blank" href="https://instagram.com/ecoteleconomia?igshid=1xblok97xka4q" className="social-link"><FaInstagram /></Nav.Link>
        <Nav.Link target="_blank" href="https://br.linkedin.com/in/ecotel-economia-589a21180" className="social-link"><FaLinkedin /></Nav.Link>
      </Nav>
    </Container>
  </Navbar>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
