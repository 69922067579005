import React from "react"
import './home-slide02.css'
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Slider from "react-slick";
import './customers.css'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  autoPlay: true,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

const Clientes = () => (
  <StaticQuery
    query={graphql`
      query allimgQuery {
        source: allFile(filter: { absolutePath: { regex: "/clientes/" } }) {
          edges {
            node {
              childImageSharp {
                fixed(height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Slider {...settings}>
        {data.source.edges.map(({ node }, i) => (
          <div className="img-holder" key={'holder' + i}>
            <Img key={'image' + i} fixed={node.childImageSharp.fixed} imgStyle={{objectFit: "none"}}  />
          </div>
        ))}
      </Slider>
    )}
  />
)

const Customers = () => (
  <Container className="customers" name="NossosClientes">
    <h2>Nossos clientes</h2>
    <Col>
      <Clientes />
    </Col>
  </Container>
);

export default Customers
