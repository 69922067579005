// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Container from "react-bootstrap/Container"
import Nav from "react-bootstrap/Nav"
import Navbar from "react-bootstrap/Navbar"
import Button from "react-bootstrap/Button"
import Logo from "./logo"
import "./sticky-header.css"
import { navigate } from "gatsby"
import * as Scroll from 'react-scroll';

const StickyHeader = React.forwardRef((props, ref) => (
  <div ref={ref} style={{zIndex: 9999, ...props.style}}>
    <Navbar collapseOnSelect expand="lg" id="sticky-header" >
      <Container>
        <Navbar.Brand href="" onClick={() => navigate("/")}>
          <Logo />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">          
          </Nav>
          <Nav>
            <div className="sibling-fade" style={{marginRight: 30}}>
              <Nav.Link onClick={() => navigate("/")}>Home</Nav.Link>
              <Nav.Link href="javascript:void(0)" eventKey={2} onClick={() =>
                Scroll.scroller.scrollTo('QuemSomos', {
                  duration: 500,
                  delay: 50,
                  smooth: true,
                  offset: -90
              })}>Quem somos</Nav.Link>
              <Nav.Link href="javascript:void(0)" eventKey={3} onClick={() =>
                Scroll.scroller.scrollTo('SaibaMais', {
                  duration: 500,
                  delay: 50,
                  smooth: true,
                  offset: -90
              })}>Saiba mais</Nav.Link>
              <Nav.Link href="javascript:void(0)" eventKey={4} onClick={() =>
                Scroll.scroller.scrollTo('NossosClientes', {
                  duration: 500,
                  delay: 50,
                  smooth: true,
                  offset: -85
              })}>Nossos clientes</Nav.Link>
              <Nav.Link href="javascript:void(0)" eventKey={5} onClick={() =>
                Scroll.scroller.scrollTo('Contato', {
                  duration: 500,
                  delay: 50,
                  smooth: true,
                  offset: -90
              })}>Contato</Nav.Link>
            </div>
            <Button onClick={() => props.openModal()} className="contact-button">Quero receber um contato</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </div>
));

StickyHeader.propTypes = {
  siteTitle: PropTypes.string,
}

StickyHeader.defaultProps = {
  siteTitle: ``,
}

export default StickyHeader
