
import React from "react"
import { FaWhatsapp, FaTimes } from 'react-icons/fa'
import './whatsapp-button.css'

const WhatsappButton = (props) => (
    <button className="whatsapp-button" onClick={props.onClick}>
        {props.isOpened ? <FaTimes className="rcw-close-launcher" /> : <FaWhatsapp className="rcw-open-launcher" />}
    </button>
)

export default WhatsappButton
