// import { Link } from "gatsby"
import React from "react"
import './footer.css'
import Jumbotron from "react-bootstrap/Jumbotron"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as Scroll from 'react-scroll';

const Footer = (props) => (
  <footer>
    <div className="footer-top">
      <Container>
        <Row>
          <Col sm={12} md={3} >
            <div className="footer-block">
              <h5 className="text-uppercase">Empresa</h5>
              <ul className="list-unstyled text-small">
                <li><a href="javascript:void(0)" onClick={() =>
                  Scroll.scroller.scrollTo('QuemSomos', {
                    duration: 500,
                    delay: 50,
                    smooth: true,
                    offset: -90
                  })}>Quem somos</a></li>
                <li><a href="javascript:void(0)" onClick={() =>
                  Scroll.scroller.scrollTo('SaibaMais', {
                    duration: 500,
                    delay: 50,
                    smooth: true,
                    offset: -90
                  })}>Saiba mais</a></li>
                <li><a href="javascript:void(0)" onClick={() =>
                  Scroll.scroller.scrollTo('NossosClientes', {
                    duration: 500,
                    delay: 50,
                    smooth: true,
                    offset: -85
                  })}>Nossos clientes</a></li>
              </ul>
            </div>
          </Col>
          <Col sm={12} md={3} >
            <div className="footer-block">
              <h5 className="text-uppercase">Contato</h5>
              <ul className="list-unstyled text-small">
                <li><a href="javascript:void(0)" onClick={() =>
                  Scroll.scroller.scrollTo('Contato', {
                    duration: 500,
                    delay: 50,
                    smooth: true,
                    offset: -90
                  })}>Fale conosco</a></li>
                <li><a href="javascript:void(0)" onClick={() => props.openModal()}>Faça uma avaliação</a></li>
              </ul>
            </div>
          </Col>
          <Col sm={12} md={3}>            
            <div className="footer-block">
              <h5 className="text-uppercase">Área restrita</h5>
              <ul className="list-unstyled text-small">
                <li><a href="https://webmail-seguro.com.br/ecoteleconomia.com.br/" target="_blank">E-mail</a></li>
              </ul>
              <br />
              <br />
              <h5 className="text-uppercase">Redes sociais</h5>
              <ul className="list-unstyled text-small">
                <li><a href="https://www.facebook.com/ecoteleconomiaetelefonia/" target="_blank">Facebook</a></li>
                <li><a href="https://br.linkedin.com/in/ecotel-economia-589a21180" target="_blank">LinkedIn</a></li>
                <li><a href="https://instagram.com/ecoteleconomia?igshid=1xblok97xka4q" target="_blank">Instagram</a></li>
              </ul>
            </div>
          </Col>
          <Col sm={12} md={3}>            
            <div className="footer-block">
              <h5 className="text-uppercase">Sobre nós</h5>
              <div className="box-about">
                <p>A Ecotel Economia é uma empresa no ramo de assessoria em telefonia fixa e móvel, com experiência de 08 anos na área oferecendo soluções inteligentes de redução de custo em telefonia.</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    <div className="footer-bottom">
      <Container>
        <span>© 2019 RSP Tecnologia. Todos os Direitos Reservados</span>
      </Container>
    </div>
  </footer>
);

export default Footer
