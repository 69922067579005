import React, { useCallback } from 'react';
import {useDropzone} from 'react-dropzone';
import './dropzone.css'

const Dropzone = (props) => {

  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length > 0) {
      props.setFile(acceptedFiles[0]);
    }
  }, [])

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({onDrop})
  
  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />        
        {
            files.length ? files : <span>Arraste e solte aqui sua fatura, ou clique e selecione o arquivo.</span>
        }
    </div>
  );
}

export default Dropzone;
